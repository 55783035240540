import React from "react";

import "./App.css";

import logo from "./assets/logo.svg";

function App() {
  return (
    <div class="container">
      <img style={{ height: 100, width: 300 }} src={logo} alt="logo" />
      <h3>Moveciti - Carro e Moto</h3>
      <h3>Política de privacidade</h3>
      <p>
        Os termos de uso são regras gerais de funcionamento do serviço e serão
        consideradas lidas, entendidas e aceitas, caso o usuário complete seu
        cadastramento no aplicativo, sem prejuízo das demais leis e normas em vigor
        relativas ao serviços de transporte de passageiros por taxistas.
      </p>
      <ol>
        <li>
          <h4>DO CADASTRAMENTO</h4>
          <ol>
            <li>
              O cadastramento dos dados para utilização deverá ser feito pelo aplicativo.
            </li>
            <li>
              Os serviços serão acessados via internet pelo aplicativo, utilizando o
              número de telefone que são de inteira responsabilidade do usuário;
            </li>
          </ol>
        </li>
        <li>
          <h4>DAS RESPONSABILIDADES DOS USUÁRIOS</h4>
          <ol>
            <li>
              A responsabilidade pela veracidade dos dados é do usuário e este deve
              responder por falsidade ideológica ou outros prejuízos causados por
              informações falsas;
            </li>
            <li>Os usuários devem informar sobre falhas no sistemas;</li>
          </ol>
        </li>
        <li>
          <h4>DAS DISPOSIÇÕES GERAIS</h4>
          <ol>
            <li>
              Estes Termos de Uso não geram nenhum contrato de sociedade, de mandato,
              franquia ou relação de trabalho com seus usuários.
            </li>
            <li>
              É expressamente vedada a reprodução, cópia, modificação ou reutilização de
              qualquer parte do nosso aplicativo ou elementos de design visual, logotipo
              ou marca, sem a nossa prévia autorização por escrito, podendo sofrer as
              sanções aplicáveis.
            </li>
            <li>
              Os serviços oferecidos pelo aplicativo são fornecidos “tal como está” e
              “conforme estiver disponível”; podendo a qualquer momento serem alterados,
              suspensos, sem prévio aviso.
            </li>
          </ol>
        </li>
        <li>
          <h4>DA PRIVACIDADE E SEGURANÇA</h4>
          <p>
            Suas informações, incluindo seus dados pessoais, são utilizadas para fornecer
            e melhorar, estética e funcionalmente, as Aplicações e Serviços Moveciti, além
            de outras finalidades estabelecidas nesta Seção. Exceto conforme previsto
            abaixo, o Moveciti não compartilhará suas informações com terceiros.
          </p>
          <p>
            Especificamente, Moveciti deverá utilizar suas informações para os seguintes
            propósitos:
          </p>
          <p>
            Fornecer, entregar, manter, desenvolver, aprimorar, divulgar e ampliar o uso
            do Moveciti Aplicativos e Serviços;
          </p>
          <p>
            Identificar você e personalizar sua experiência e uso das Aplicações e
            Serviços Moveciti, inclusive para fins promocionais;
          </p>
          <p>
            Gerar análises estatísticas e relatórios sobre o funcionamento e operação da
            Moveciti e Serviços, para o benefício do Moveciti e/ou de seus parceiros;
          </p>
          <p>
            Permitir que o Moveciti entre em contato com você a respeito das Aplicações e
            Serviços que ele disponibiliza e fornecer suporte ao usuário;
          </p>
          <p>
            Fornecer e melhorar os mecanismos de segurança e monitoramento para as
            Aplicações e Serviços Moveciti, incluindo o monitoramento de segurança durante
            seu uso;
          </p>
          <p>
            Monitorar a prestação de serviços pelos parceiros e garantir, na medida do
            possível, sua qualidade e segurança;
          </p>
          <p>
            Detectar e proteger o Moveciti e os usuários contra fraudes, abusos ou atos
            ilegais, bem como preservar a segurança e a integridade física do usuário;
          </p>
          <p>
            Detectar e impedir o uso de aplicativos e programas para fins fraudulentos ou
            para alterar a funcionalidade dos aplicativos e serviços fornecidos pelo
            Moveciti;
          </p>
          <p>Desenvolver novos produtos, serviços e funcionalidades;</p>
          <p>Realizar controle de risco e análise de antecedentes criminais; </p>
          <p>
            Cumprir as obrigações legais de coleta e compartilhamento de dados com o
            governo, na forma de leis e regulamentos aplicáveis;
          </p>
          <p>
            Possibilitar a defesa do Moveciti em quaisquer eventuais processos judiciais.
          </p>
          <p>
            Cumprir com as normas anti-lavagem de dinheiro e financiamento do terrorismo
            (KYC), verificações de perfil e histórico de transações, de acordo com os
            regulamentos aplicáveis sobre prevenção de lavagem de dinheiro.
          </p>
          <p>
            Suas informações de contato são utilizadas para que O Moveciti se comunique
            diretamente com você e lhe envie informações relevantes, incluindo informações
            sobre transações, avisos sobre a aplicação e serviços, ofertas especiais,
            anúncios de eventos, notícias, melhores práticas e muito mais.
          </p>
          <p>
            Seus dados financeiros são utilizados para fazer pagamentos das viagens e
            entregas efetuadas e para transferir os respectivos valores para os
            Motoristas/Condutores/Moto-taxi e estabelecimentos parceiros, além das
            possibilidades de compartilhamento previstas abaixo.
          </p>
          <p>
            O Moveciti pode utilizar outras informações sobre você disponíveis em fontes
            terceiras e combiná-las com informações coletadas diretamente de você, de
            acordo com esta Política de Privacidade, para todos os fins estabelecidos
            nesta Seção. Nesse caso, somente as informações coletadas diretamente de você
            pelo Moveciti e o resultado da combinação são cobertas e sujeitas a esta
            Política de Privacidade.
          </p>
          <p>
            O Moveciti não deverá compartilhar ou fornecer a terceiros suas informações,
            incluindo seus dados pessoais, exceto:
          </p>
          <ul>
            <li>quando você solicitar ou autorizar o Moveciti a compartilhar;</li>
            <li>
              para permitir a entrega e operação do Moveciti Aplicações e Serviços da
              maneira e forma adequadas que você contratou;
            </li>
            <li>
              aos parceiros comerciais que prestam serviços através da plataforma, ou que
              trabalham em nome do Moveciti, incluindo métodos de pagamento, de acordo com
              as especificações e diretrizes determinadas pelo Moveciti, incluindo a
              provisão contratual do dever de manter a confidencialidade das informações
              por esses terceiros;
            </li>
            <li>
              aos usuários que utilizam a plataforma para contratar o parceiro, sendo que
              as informações básicas do respectivo parceiro contratado serão fornecidas;
            </li>
            <li>
              aos órgãos, autoridades e outras entidades do poder público, para cumprir
              com as leis e regulamentos aplicáveis às Aplicações, Serviços e/ou o serviço
              prestado pelos usuários das plataformas Moveciti;
            </li>
            <li>
              a agências, autoridades e outras entidades governamentais, assim como a
              pessoas físicas ou jurídicas, a fim de cumprir com obrigações legais ou
              ordens judiciais;
            </li>
            <li>
              à autoridade policial ou ao representante do Ministério Público,
              independentemente da existência de obrigação legal específica ou ordem
              judicial, quando, a critério dO Moveciti, houver suspeita, violação
              flagrante ou investigação de crime com violência ou ameaça grave envolvendo
              usuários das Aplicações, ou necessário para proteger os direitos,
              propriedade ou segurança do Moveciti, seus usuários ou terceiros;
            </li>
            <li>
              para o exercício e defesa de qualquer dos direitos do Moveciti, a seu
              critério, inclusive em processos judiciais ou administrativos; e
            </li>
            <li>
              em caso de mudança na propriedade ou controle do Moveciti, devido a fusão,
              aquisição ou venda de ativos.
            </li>
          </ul>
        </li>
        <li>
          <h4>ACESSO A LOCALIZAÇÃO</h4>
          <ol>
            <li>
              O Moveciti utiliza os dados de localização de seu dispositivo a fim de
              fornecer o melhor serviço para você. Estas informações são usadas para
              garantir a máxima segurança e conforto em sua viagem, entregas e outros
              serviços. Além disso, estes dados são muito importantes para que possamos
              melhorar outros aspectos das aplicações, como a identificação do local onde
              o usuário encontrará o motorista ou o entregador, entre outros. Em seu
              dispositivo, você pode definir seus dados de localização de três maneiras
              diferentes:
            </li>
            <li>
              <b>Permitir que o aplicativo acesse a localização o tempo todo.</b> Neste
              modo, podemos coletar dados de localização o tempo todo, sem ter que abrir o
              aplicativo. Os dados serão coletados em segundo plano, sem interferência
              notável com o plano de dados do dispositivo ou da bateria. É o modo mais
              apropriado para que possamos fornecer o serviço de forma mais dinâmica e
              poder melhorar nossas aplicações;
            </li>
            <li>
              <b>Permitir que o aplicativo acesse o local somente durante o uso. </b>
              Os dados só serão coletados quando a aplicação estiver aberta. Este modo
              permite solicitar caronas e entregas sem ter que entrar no endereço,
              rastrear o status de sua viagem ou entrega, entre outras características;
            </li>
            <li>
              <b>Negar acesso ao local.</b> O Moveciti não poderá acessar seus dados de
              localização e, consequentemente, não poderemos fornecer muitos recursos que
              se baseiam nestas informações. Para motoristas, moto-taxistas taxista e
              entregadores, é imperativo e indispensável que os dados de localização
              estejam sempre habilitados, pois eles são essenciais para a operação do
              serviço.
            </li>
            <li>
              Caso você não forneça alguma das informações solicitadas, o Moveciti não
              pode garantir a qualidade e precisão das Aplicações e Serviços do Moveciti.
              Caso você não forneça informações essenciais para a operação das Aplicações
              ou da prestação dos Serviços, o Moveciti poderá excluí-lo imediatamente das
              aplicações móveis do Moveciti, inclusive para garantir a operação tranquila
              e segura das Aplicações e Serviços para outros usuários.
            </li>
          </ol>
        </li>
        <li>
          <h4>DA EXCLUSÃO DA CONTA</h4>
          <p>
            Caso o usuário não tenha mais interesse nos serviços prestados pela
            plataforma, o mesmo poderá solicitar ou realizar a exclusão do cadastro. Uma
            vez concluído o precedimento, todos os dados serão excluidos de forma
            imediata, note que a exclusão não pode ser desfeita. A exclusão da conta do
            usuário poderá ser realizada pelas seguintes formas:
          </p>
          <ol>
            <li>
              Mediante pedido aos administradores da Plataforma através do e-mail de
              suporte ao usuário lucas.lldcosta@gmail.com.
            </li>
            <li>
              Através do própio aplicativo: No menu lateral selecione a opção "Meus dados"
              em seguida seleciona a opção "Excluir conta".
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
}

export default App;
